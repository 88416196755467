
export default ({
	namespaced: true,
	state: {
		pointUnit:'',
		pointName:'',
		listScroll:false,
		listScrollMore:false,
		memberName:'',
	},
	getters: {
		getPointName: state => {
			return state.pointName;
		},
		getPointUnit: state => {
			return state.pointUnit;
		},
		getListScroll: state => {
			return state.listScroll;
		},
		getListScrollMore: state => {
			return state.listScrollMore;
		},
		getMemberName: state => {
			return state.memberName;
		},

	},
	mutations: {
		setPointUnit(state,unit) {
			state.pointUnit = unit;
		},
		setPointName(state,name) {
			state.pointName = name;
		},
		setListScroll(state,bool) {
			state.listScroll = bool;
		},
		setListScrollMore(state,bool) {
			state.listScrollMore = bool;
		},
		setMemberName(state,name) {
			state.memberName = name;
		},
	},
	actions: {
		setPointUnit({commit},unit) {
			commit('setPointUnit',unit)
		},
		setPointName({commit},name) {
			commit('setPointName',name)
		},
		setListScroll({commit},bool) {
			commit('setListScroll',bool)
		},
		setListScrollMore({commit},bool) {
			commit('setListScrollMore',bool)
		},
		setMemberName({commit},name) {
			commit('setMemberName',name)
		},
    }
})
