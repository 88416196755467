
export default ({
	namespaced: true,
	state: {
		class:'main',
		title:'',
		backUrl:'',
		bottom:true,
	},
	getters: {
		getClass: state => {
			return state.class;
		},
		getTitle:state => {
			return state.title;
		},
		getBackUrl:state => {
			return state.backUrl;
		},
		getBottom:state => {
			return state.bottom;
		}
		
	},
	mutations: {
		setClass(state,name) {
			state.class = name;
		},
		setTitle(state,title) {
			state.title = title;
		},
		setBackUrl(state,url) {
			state.backUrl = url;
		},
		setBottom(state,bool) {
			state.bottom = bool;
		},
	},
	actions: {
		setClass({commit},name) {
			commit('setClass',name)
		},
		setTitle({commit},title) {
			commit('setTitle',title)
		},
		setBackUrl({commit},url) {
			commit('setBackUrl',url)
		},
		setBottom({commit},bool) {
			commit('setBottom',bool)
		},
		
	}
})
