
export default ({
	namespaced: true,
	state: {
		pageMenuName:'',
	},
	getters: {
		getPageMenuName: state => {
			return state.pageMenuName;
		},

	},
	mutations: {
		setPageMenuName(state,name) {
			state.pageMenuName = name;
		},

	},
	actions: {
		setPageMenuName({commit},name) {
			commit('setPageMenuName',name)
		},
    }
})
