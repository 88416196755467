
import config from './modules/config'
import mobile from './modules/mobile'
import admin from './modules/admin'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		admin,
		config,
		mobile
	}
})
